.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #E30432;
  font-size: 12px;
  line-height: 1.33333333;
  cursor: pointer;
  color: #fff;
  padding: 12px 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.08em;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #ca042c;
}
.button:active {
  background-color: #980321;
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1024px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
.navigation div.sub2 > .item {
  margin: 0 30px;
  position: relative;
}
.navigation div.sub2 > .item.init {
  margin-left: 0;
}
.navigation div.sub2 > .item.exit {
  margin-right: 0;
}
.navigation div.sub2 > .item:hover > .menu,
.navigation div.sub2 > .item:focus > .menu {
  color: #E30432;
}
.navigation div.sub2 > .item > .menu {
  text-decoration: none;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  padding-bottom: 6px;
  color: #000000;
  font-size: 17px;
  line-height: 1.76470588;
}
.navigation div.sub2 > .item > .menu.path {
  color: #E30432;
}
@media (max-width: 1523px) {
  .navigation div.sub2 > .item {
    margin: 0 12px;
  }
  .navigation div.sub2 > .item > .menu {
    font-size: 13px;
    line-height: 2.30769231;
  }
}
.navigation div.sub3 {
  position: absolute;
  left: -10px;
  top: 100%;
  background-color: #fff;
  padding: 10px;
  display: none;
}
.navigation div.sub3 > .item {
  width: 100%;
  border: 1px solid #ccc;
  border-width: 1px 0;
  margin-bottom: -1px;
  position: relative;
}
.navigation div.sub3 > .item.init {
  border-top: none;
}
.navigation div.sub3 > .item.exit {
  border-bottom: none;
}
.navigation div.sub3 > .item > .menu {
  white-space: nowrap;
  color: #000000;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.25;
  padding: 4px 6px;
}
.navigation div.sub3 > .item > .menu:hover,
.navigation div.sub3 > .item > .menu:focus,
.navigation div.sub3 > .item > .menu.path {
  background-color: #E30432;
  color: #fff;
}
.navigation div.sub2 > .item:hover div.sub3 {
  display: block;
}
.navigation div.sub4 {
  position: absolute;
  left: 100%;
  top: -10px;
  background-color: #fff;
  padding: 10px;
  display: none;
}
.navigation div.sub4 > .item {
  width: 100%;
  border: 1px solid #ccc;
  border-width: 1px 0;
  margin-bottom: -1px;
}
.navigation div.sub4 > .item.init {
  border-top: none;
}
.navigation div.sub4 > .item.exit {
  border-bottom: none;
}
.navigation div.sub4 > .item > .menu {
  white-space: nowrap;
  color: #000000;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.25;
  padding: 4px 6px;
}
.navigation div.sub4 > .item > .menu:hover,
.navigation div.sub4 > .item > .menu:focus,
.navigation div.sub4 > .item > .menu.path {
  background-color: #E30432;
  color: #fff;
}
.navigation div.sub3 > .item:hover div.sub4 {
  display: block;
}
#home {
  height: 60px;
  margin: 20px 0 20px 20px;
}
.section--multimood {
  border-top-width: 100px;
}
.section--two .area .pure.slim .part:last-child {
  padding-top: 10px;
}
.section--two .area .seam {
  margin-top: 26px;
  margin-bottom: 26px;
}
.section--one .area {
  width: 100%;
}
.section--one .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 1.14006515%;
  margin-left: 1.14006515%;
  width: 97.71986971%;
}
.section--one .area .tiny {
  width: 47.71986971%;
}
.section--one .area > .slim {
  width: 50%;
}
.section--one .area > .slim .head,
.section--one .area > .slim .foot,
.section--one .area > .slim .part {
  margin-right: 2.28013029%;
  margin-left: 2.28013029%;
  width: 95.43973941%;
}
.section--one .area > .slim.cb-album .body {
  margin-right: 2.28013029%;
  margin-left: 2.28013029%;
  width: 95.43973941%;
}
.section--one .area > .slim .tiny {
  width: 95.43973941%;
}
.section--two .area {
  width: 100%;
}
.section--two .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area .cb-album .body,
.section--two .area .head,
.section--two .area .foot,
.section--two .area .part {
  margin-right: 1.15511551%;
  margin-left: 1.15511551%;
  width: 97.68976898%;
}
.section--two .area .tiny {
  width: 31.02310231%;
}
.section--two .area > .slim {
  width: 33.33333333%;
}
.section--two .area > .slim .head,
.section--two .area > .slim .foot,
.section--two .area > .slim .part {
  margin-right: 3.46534653%;
  margin-left: 3.46534653%;
  width: 93.06930693%;
}
.section--two .area > .slim.cb-album .body {
  margin-right: 3.46534653%;
  margin-left: 3.46534653%;
  width: 93.06930693%;
}
.section--two .area > .slim .tiny {
  width: 93.06930693%;
}
.section--one .area {
  width: 102.33333333%;
  margin-left: -1.16666667%;
}
.section--two .area {
  width: 102.36486486%;
  margin-left: -1.18243243%;
}
.section--two .area .seam {
  margin-left: 0.82508251%;
  margin-right: 0.82508251%;
  width: 98.34983498%;
}
.section--two .area .seam .part {
  width: 33.33333333%;
  padding-left: 4px;
  padding-right: 4px;
}
.section--two .area .flat.wide {
  width: 66.66666667%;
}
.section--two .area .flat.wide .part {
  margin-left: 1.73267327%;
  margin-right: 1.73267327%;
  width: 96.53465347%;
}
/*# sourceMappingURL=./screen-large.css.map */